import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

function AboutPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="About"
      />

      <section>
        <h2 className="text-4xl font-bold inline-block my-8 py-3">
          O Nas
        </h2>
        <span className="block px-4 text-gray-700 text-lg">
          <span className="block mb-6">
            Telekom Communications Inc. założona została w maju 2003 roku.
            Powstanie firmy wiązało się z wielką potrzebą korzystania z usług telefonicznych-telefonii komórkowej przez mieszkańców w aglomeracji chicagowskiej.
            Jednocześnie wprowadzenie na teren Stanów Zjednoczonych usług sieci T-Mobile spowodowało, że ogromne rzesze naszych rodaków dołączylo do serwisu tej sieci.
            Wiązało się to nie tylko z możliwością korzystania na terenie Stanów Zjednoczonych ale również z telefonów T-Mobile można było korzystać w Polsce, dlatego też nasza firma świadcząca usługi telefoniczne rosła z dnia na dzień.
            Dokładając powoli również różne inne serwisy pozwoliło nam to zdobyć tysiące zadowolonych z naszych usług klientów.
          </span>
          <span className="block mb-6">
            Wraz z pojawieniem się nowych i konkurencyjnych do T-Mobile firm, nawiązaliśmy współpracę z nowymi dostawcami telefonii komórkowej, między innymi takimi jak: Simple Mobile, H20, Red Pocket.
          </span>
          <span className="block">
            Wspólpracujemy też z takimi kompaniami, Pre Cash, realizując przekazy międzynarodowe i przyjmując opłaty za rachunki użyteczności publicznej (za gaz, energię elektryczną, kabel, internet, telefon, itp.).
          </span>
        </span>
      </section>
    </Layout>
  );
}

export default AboutPage;
